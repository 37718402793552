<template>
	<div class="status">
		<v-container>
			<h4 align="center">{{ farm_name }}</h4>
			<v-row align="center" justify="center">
				<v-btn
					icon
					elevation="2"
					outlined
					class="ma-2"
					@click="$router.push('farm')"
				>
					<v-icon> mdi-arrow-left </v-icon>
				</v-btn>
				<h1>{{ green_house_name }}</h1>
			</v-row>
		</v-container>
		<v-row no-gutters>
			<v-col md="8" offset-md="2">
				<v-card class="pa-5" rounded>
					<v-card-text
						><h2 align="center">กราฟ{{ weather }}</h2>
					</v-card-text>
					<v-row>
						<v-col md="6"
							>เริ่ม
							<v-dialog
								ref="dialog1"
								v-model="modal1"
								:return-value.sync="date1"
								persistent
								width="290px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										:value="computedDateFormattedMomentjs1"
										outlined
										readonly
										v-bind="attrs"
										v-on="on"
										locale="thai"
										@click:clear="date1 = null"
										prepend-icon="mdi-calendar"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="date1"
									scrollable
									locale="th"
								>
									<v-spacer></v-spacer>
									<v-btn
										text
										color="primary"
										@click="modal1 = false"
									>
										ยกเลิก
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.dialog1.save(date1)"
									>
										ตกลง
									</v-btn>
								</v-date-picker>
							</v-dialog>
						</v-col>
						<v-col md="6">
							สิ้นสุด
							<v-dialog
								ref="dialog2"
								v-model="modal2"
								:return-value.sync="date2"
								persistent
								width="290px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										:value="computedDateFormattedMomentjs2"
										outlined
										readonly
										v-bind="attrs"
										v-on="on"
										locale="thai"
										@click:clear="date2 = null"
										prepend-icon="mdi-calendar"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="date2"
									scrollable
									locale="th"
								>
									<v-spacer></v-spacer>
									<v-btn
										text
										color="primary"
										@click="modal2 = false"
									>
										ยกเลิก
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.dialog2.save(date2)"
									>
										ตกลง
									</v-btn>
								</v-date-picker>
							</v-dialog>
						</v-col>
					</v-row>
					<center>
						<v-btn
							block
							@click="table_environment_by_senser"
							color="amber"
						>
							ค้นหา
						</v-btn>
					</center>
					<br />
					<LineChartGenerator
						:chart-options="chartOptions"
						:chart-data="chartData"
						:chart-id="chartId"
						:dataset-id-key="datasetIdKey"
						:plugins="plugins"
						:css-classes="cssClasses"
						:styles="styles"
						:width="width"
						:height="height"
					/>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	LineElement,
	LinearScale,
	CategoryScale,
	PointElement,
} from "chart.js";
ChartJS.register(
	Title,
	Tooltip,
	Legend,
	LineElement,
	LinearScale,
	CategoryScale,
	PointElement
);

export default {
	name: "status",
	data: () => ({
		farm_name: "",
		green_house_name: "",
		chartData: {},
		chartOptions: {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
						},
					},
				],
			},
		},
		date1: format(
			parseISO(new Date().toISOString()),
			"yyyy-MM-dd 00:00:00"
		),
		date2: format(
			parseISO(new Date().toISOString()),
			"yyyy-MM-dd 23:59:59"
		),
		modal1: false,
		modal2: false,
		weather: null,
	}),
	components: { LineChartGenerator },
	// props: {
	//   chartId: {
	//     type: String,
	//     default: "line-chart",
	//   },
	//   datasetIdKey: {
	//     type: String,
	//     default: "label",
	//   },
	//   width: {
	//     type: Number,
	//     default: 400,
	//   },
	//   height: {
	//     type: Number,
	//     default: 400,
	//   },
	//   cssClasses: {
	//     default: "",
	//     type: String,
	//   },
	//   styles: {
	//     type: Object,
	//     default: () => {},
	//   },
	//   plugins: {
	//     type: Array,
	//     default: () => [],
	//   },
	// },
	created: function () {
		window.scrollTo(0, 0);
		this.farm_name = this.$session.get("farm_data").farm_name;
		this.green_house_name =
			this.$session.get("green_house_item").green_house_name;
		this.table_environment_by_senser();
		this.weather = this.$session.get("weather").typeThai;
	},
	computed: {
		computedDateFormattedMomentjs1() {
			return this.date1
				? moment(this.date1).add(543, "year").locale("th").format("LL")
				: "";
		},
		computedDateFormattedMomentjs2() {
			return this.date2
				? moment(this.date2).add(543, "year").locale("th").format("LL")
				: "";
		},
	},

	watch: {},

	methods: {
		async table_environment_by_senser() {
			var self = this;
			var config = {
				method: "get",
				url: "https://cool-agri.com/hydroponic_portal/api/v1/table_environment_by_senser",
				headers: {
					Authorization: localStorage.getItem("jwt"),
					"Content-Type": "application/json",
				},
				params: {
					table_id: this.$session.get("table_data").table_id,
					sensor: this.$session.get("weather").typeEng,
					date_start: self.date1,
					date_end: self.date2,
				},
			};
			await this.axios(config)
				.then(function (response) {
					console.log(response.data);
					if (response.data.success == 1) {
						let chartDataFromApi = {
							labels: [],
							data: [],
						};
						response.data.data.reverse().forEach((element) => {
							chartDataFromApi.labels.push(element.date);
							if (element.temperature != undefined) {
								chartDataFromApi.data.push(element.temperature);
							}
							if (element.temperature_water != undefined) {
								chartDataFromApi.data.push(
									element.temperature_water
								);
							}
							if (element.light != undefined) {
								chartDataFromApi.data.push(element.light);
							}
						});
						self.genChartjs(chartDataFromApi);
					}
				})
				.catch(function (error) {
					console.log(error);
					this.$router.push("/");
				});
		},
		genChartjs(cData) {
			console.log(this.chartData);
			console.log(cData);
			this.chartData = {
				labels: cData.labels,
				datasets: [
					{
						label: this.$session.get("weather").typeThai,
						backgroundColor: "#f87979",
						data: cData.data,
						fill: false,
						tension: 0.1,
					},
				],
			};
		},
	},
};
</script>

<style></style>
